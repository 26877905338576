import _ from "lodash";
import * as d3 from "d3";
import newDatabaseCSV from "../db/NEW_Database.csv";
import sourceStringsCSV from "../db/Source Strings.csv";

export const csvToJSON = async type => {
  const newDatabase = await d3.csv(newDatabaseCSV);
  let data = [];
  switch (type) {
    case "Source Strings":
      data = await d3.csv(sourceStringsCSV);
      break;
    case "Drug":
      data = parseDrugs(newDatabase);
      break;
    default:
      data = parseNewDatabase(newDatabase);
      break;
  }
  return data;
};
const parseNewDatabase = database => {
  const output = database.map(row => {
    return {
      drug: row["Drug"],
      sn: row["SN"],
      indicationGroup: row["Indication Group"],
      indication: row["Indication"],
      subIndication: row["Sub-indication"],
      doseStage: row["dose_stage"],
      weightMin: row["weight_min_kg"] || 0,
      weightMax: row["weight_max_kg"]
        ? parseFloat(row["weight_max_kg"].split("<").join(""))
        : 0,
      ageMin: row["age_min_mth"] || 0,
      ageMax: row["age_max_mth"]
        ? parseFloat(row["age_max_mth"].split("<").join(""))
        : 0,
      route: row["route"],
      doseUnit: row["dose_unit"],
      fixedDose: row["fixed dose"] || 0,
      dailyDosePerKgLow: row["daily_doseperkg_low"] || 0,
      dailyDosePerKgHigh: row["daily_doseperkg_high"] || 0,
      divisor: row["divisor"] || 0,
      maxSingleDose: row["max_single_dose"] || "",
      frequency: isNaN(row["frequency"])
        ? row["frequency"]
        : `q${row["frequency"]}hr`,
      duration: row["duration"] || "",
      additionalInfo: row["additional_info"] || ""
    };
  });
  return output;
};
const parseDrugs = database => {
  const data = database.map(d => d["Drug"]);
  const sortedData = _.uniq(data);
  const filterEmpty = _.compact(sortedData);
  return filterEmpty;
};
