import axios from "axios";

// Search for drug names matching the searchString

function getServerUrl() {
  if (window.location.hostname === "localhost") {
    return "https://hippocrates.staging.botmd.io/";
  }

  return window.location.origin.replace("medicalcalcs", "hippocrates") + "/";
}

const DRUG_INTERACTIONS_URL = getServerUrl() + "medscape_interaction";
const DRUG_NAME_SEARCH_URL = getServerUrl() + "medscape_suggest";

export function drugNameSearchRequest(searchString, source) {
  return axios
    .get(DRUG_NAME_SEARCH_URL, {
      cancelToken: source && source.token,
      params: {
        q: searchString,
        sz: 20
      }
    })
    .then(res => (res.data.types[0] ? res.data.types[0].references : []));
}

// Find interactions between the drug ids in the drugIds array. Drug id retrieved from medscape
export function drugIdsInteractionsRequest(drugIds, source) {
  let drugIdsParam = drugIds.join(",");

  return axios.get(DRUG_INTERACTIONS_URL, {
    cancelToken: source.token,
    params: {
      ids: drugIdsParam
    }
  });
}
