import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { csvToJSON } from "../../../../../utils/gapi";
import { typeInputSearch } from "../../../../../store/modules/calculator";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestions(suggestions, value) {
  const cleanedInput = deburr(value.trim()).toLowerCase();
  const inputLength = cleanedInput.length;
  if (inputLength === 0) {
    return [];
  }
  let count = 0;
  return suggestions.filter(function(suggestion) {
    if (count > 5) {
      return false;
    }
    const isMatching = suggestion.toLowerCase().search(cleanedInput) !== -1;
    if (isMatching) {
      count = count + 1;
    }
    return isMatching;
  });
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

class IntegrationAutosuggest extends React.Component {
  state = {
    input: "",
    suggestionsList: [],
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getSuggestions(this.state.suggestionsList, value);
    this.setState({ suggestions });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  updateValue(newValue) {
    // update both redux store and component state
    const { typeInputSearch, group } = this.props;
    typeInputSearch(group, newValue);
    this.setState({
      input: newValue
    });
  }

  populateInitialDrug() {
    const params = new URL(document.location).searchParams;
    const drug = params.get("drug");

    if (drug) {
      this.updateValue(drug);
    }
  }
  componentDidMount = () => {
    csvToJSON(this.props.values).then(suggestionsList =>
      this.setState({ suggestionsList })
    );
    this.populateInitialDrug();
  };
  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: this.props.placeholder,
            value: this.state.input,
            onChange: (event, { newValue }) => this.updateValue(newValue)
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.divider} />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { typeInputSearch }
)(withStyles(styles)(IntegrationAutosuggest));
