// Primary color
export const DOC_BLUE_DEFAULT = "#03A9F4";

// Dark Primary Color
export const DOC_BLUE_700 = "#007ac1";

// User speech bubble
export const DOC_BLUE_400 = "#29B6F6";

//
export const DOC_BLUE_50 = "#B3E5FC";

// Standard icons
export const DEEP_BLUE = "#1496EA";

// Blue highlight text
// TODO CHECK THE SAME NAMING
export const DOC_BLUE_700_2 = "#E1F5FE";

// Bot speech bubble
export const WARM_GREY = "#F5F5F5";

// Secondary Color (FAB)
export const FAB = "#1565C0";

// Used for ’No Interaction’ search results
export const CLEAR_GREEN = "#00D7CC";

// Used for ’Significant’ drug results
export const MID_ORANGE = "#F9AF14";

// Used for ’Serious’ drug results
export const ALERT_RED = "#FF616F";

// Used in Icons
export const ICON_TURQUOISE = "#34C1D6";

// Used in Icons
export const ICON_YELLOW = "#FDCA73";

// Used in Icons
export const ICON_RED = "#FF616F";

// Used in Icons
export const ICON_DARK_GREY = "#5C6474";

// Separator Grey Color
export const SEPARATOR_GREY = "#e5e6e8";

// Grey Bg Color
export const GREY_BG = "#fafbfd";
