/* TODO: This component is heavily coupled with drug-interaction calculator functionality */
import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import {
  typeInputSearch,
  searchListAddItem,
  searchListRemoveItem,
  searchListClear
} from "../../../../../store/modules/calculator";
import * as drugsApi from "../../../../../utils/drugsApi";
import store from "../../../../../store/index";
import hideMobileKeyboard from "../../../../../utils/hideMobileKeyboard";

const autosuggestStyles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    maxHeight: "30vh",
    overflow: "scroll"
  },
  suggestionsListPaper: {
    overflow: "visible"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem
      onClick={() => {
        store.dispatch(searchListAddItem(suggestion));
        hideMobileKeyboard();
      }}
      selected={isHighlighted}
      component="div"
      style={{
        marginTop: "15px"
      }}
    >
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {suggestion.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

// empty input upon option selected
function getSuggestionValue(suggestion) {
  return "";
}

class IntegrationAutosuggest extends React.Component {
  constructor() {
    super();
    this.state = {
      input: "",
      suggestions: [],
      suggestionsLoading: false,
      error: false
    };

    this.previousRequestSource = null;
  }

  newSource() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
  }

  cancelPreviousRequest() {
    this.previousRequestSource.cancel("Previous request cancelled by user.");
    this.setState({ suggestionsLoading: false });
  }

  // find matching suggestions from list of matching results
  findMatchingSuggestions(suggestions, value) {
    const inputLength = value.length;
    let count = 0;
    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            count < 5 &&
            suggestion.text.slice(0, inputLength).toLowerCase() === value;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  // load suggestions from api and update suggestions list
  loadSuggestions(query) {
    if (this.state.suggestionsLoading) {
      this.cancelPreviousRequest();
    }

    this.setState({ suggestionsLoading: true });

    this.previousRequestSource = this.newSource();
    return drugsApi
      .drugNameSearchRequest(query, this.previousRequestSource)
      .then(suggestions => {
        this.setState({
          suggestionsLoading: false,
          suggestions
        });
      })
      .catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log("Request canceled", thrown.message);
        } else {
          console.log(thrown);
        }
      });
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    if (inputValue.length > 1) {
      this.loadSuggestions(inputValue);
    } else {
      this.handleSuggestionsClearRequested();
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    const { typeInputSearch, group } = this.props;
    typeInputSearch(group, newValue);
    this.setState({
      [name]: newValue
    });
  };

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: "Add a drug",
            value: this.state.input,
            onChange: this.handleChange("input")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper
              className={classes.suggestionsListPaper}
              {...options.containerProps}
              square
            >
              {options.children}
            </Paper>
          )}
        />
        <div className={classes.divider} />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired
};

const ConnectedIntegrationAutosuggest = connect(
  state => ({}),
  { typeInputSearch }
)(withStyles(autosuggestStyles)(IntegrationAutosuggest));

class ItemList extends React.Component {
  // populate itemlist with initial items from url querystring
  loadInitialItems() {
    const params = new URL(document.location).searchParams;
    const data = params.get("drugs");
    if (data == null) {
      console.log("No initial items.");
      return;
    } else {
      const drugNames = JSON.parse(data);
      for (const searchString of drugNames) {
        const cleanedSearchString = searchString.toLowerCase().trim();
        drugsApi.drugNameSearchRequest(cleanedSearchString).then(function(matches) {
          if (matches.length === 1) {
            store.dispatch(searchListAddItem(matches[0]));
          } else {
            // add drug with exact name if exists TODO: optimize since anecdotally, matching drug name tends to appear in first result
            for (const match of matches) {
              if (match.text.toLowerCase() === cleanedSearchString) {
                store.dispatch(searchListAddItem(match));
                break;
              }
            }
          }
        });
      }
    }
  }
  componentDidMount() {
    this.loadInitialItems();
  }
  render() {
    const { classes } = this.props;
    if (this.props.searchListItems && this.props.searchListItems.length > 0) {
      return (
        <List className={classes.list} dense={true}>
          <Button
            onClick={() => store.dispatch(searchListClear())}
            color="primary"
            size="small"
            className={classes.button}
          >
            CLEAR ALL
          </Button>
          {this.props.searchListItems &&
            this.props.searchListItems.map(item => (
              <div key={item.id}>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    disableTypography
                    className={classes.listItemText}
                    primary={item.text}
                  />
                  <ListItemSecondaryAction
                    onClick={() =>
                      store.dispatch(searchListRemoveItem(item.id))
                    }
                  >
                    <IconButton edge="end" aria-label="Delete">
                      <RemoveCircleIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            ))}
        </List>
      );
    }
    return null;
  }
}
const itemListStyles = theme => ({
  list: {
    // allow itemlist to take up 100% of space
    width: "100vw",
    marginLeft: `-${theme.spacing.unit * 2}px`,
    marginRight: `-${theme.spacing.unit * 2}px`,
    backgroundColor: "#ffffff",
    minHeight: 0,
    display: "flex",
    flexDirection: "column"
  },
  listItemText: {
    fontWeight: "300",
    fontSize: "16px"
  },
  button: {
    fontWeight: "500",
    fontSize: "12px",
    marginLeft: "auto"
  }
});

const ConnectedItemList = connect(
  state => ({
    searchListItems:
      state && state.calculator.data && state.calculator.data.searchListItems
  }),
  { searchListRemoveItem }
)(withStyles(itemListStyles)(ItemList));

class SearchList extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ConnectedIntegrationAutosuggest />
        <Divider className={classes.divider} />
        <ConnectedItemList />
      </div>
    );
  }
}

const searchListStyles = theme => ({
  root: {
    minHeight: "200px"
  },
  divider: {
    width: "100vw",
    marginLeft: `-${theme.spacing.unit * 2}px`,
    marginRight: `-${theme.spacing.unit * 2}px`
  }
});

export default withStyles(searchListStyles)(SearchList);
